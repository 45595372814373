import { Link } from "gatsby"
// import { capitalize as cap } from "lodash"

function NoteOl({ typeLink, typeName, notes }) {
  return (
    <li
      sx={{
        fontFamily: "body",
        fontWeight: "body",
        fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
        counterReset: "section",
        textAlign: "center",
        paddingTop: "2rem"
      }}
    >
      <Link
        to={`/notes/${typeLink}/`}
        sx={{ variant: "links.nav", textDecoration: "underline" }}
      >
        {typeName}
      </Link>
      <ol
        sx={{
          paddingTop: "2rem",
          paddingBottom: "2rem",
          marginLeft: "4rem",
          textAlign: "left"
        }}
      >
        {notes.map((note) => {
          return (
            <li
              key={note.id}
              data-order={note.order}
              sx={{
                paddingBottom: "1rem",
                fontFeatureSettings:
                  "'kern' 1, 'liga' 1, 'dlig' 1,'case' 1, 'onum' 1, 'tnum' 1, 'smcp' 0",
                counterIncrement: "section",
                "&:before": {
                  content: "attr(data-order) '. '",
                  fontFeatureSettings:
                    "'kern' 1, 'liga' 1, 'dlig' 1,'case' 1, 'onum' 1, 'tnum' 1, 'smcp' 0",
                  variant: "text.listMarker",
                  display: "block",
                  float: "left",
                  height: "3rem",
                  marginRight: "1rem"
                }
              }}
            >
              <Link
                to={`../${note.slug}`}
                sx={{ variant: "links.body", fontStyle: "italic" }}
              >
                {" "}
                {note.title}
              </Link>
            </li>
          )
        })}
      </ol>
    </li>
  )
}

export default NoteOl
