import * as sass from "./graf.module.scss"

const Graf = ({ children }) => {
  return (
    <p className={sass.graf} sx={{ variant: "text.graf" }}>
      {children}
    </p>
  )
}

export default Graf
