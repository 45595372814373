import { Flex } from "theme-ui"
import { HomeLink, NavLink } from "./links"
import { HeaderNav } from "./nav"

const Masthead = ({ siteTitle, menu }) => {
  return (
    <Flex
      as="header"
      sx={{
        variant: "layout.wide",
        flexFlow: "column nowrap",
        justifyContent: "space-between",
        alignItems: "center",
        mx: "auto",
        mb: "2rem",
        py: "2rem",
        maxHeight: "9rem",
        "&:after": {
          variant: "decor.navigation",
          marginTop: ["4.2rem", "3rem"] //"calc(3rem + 1px)" //["32px", "35px", "38px", "41px", "44px"]
        }
      }}
    >
      <HomeLink siteTitle={siteTitle} />
      <HeaderNav>
        {menu.map((item) => (
          <NavLink key={item.label} link={item.link} label={item.label} />
        ))}
      </HeaderNav>
    </Flex>
  )
}

export default Masthead
