import { Flex } from "theme-ui"

const LetterHeader = ({ children }) => {
  return (
    <Flex
      as="header"
      sx={{
        variant: "layout.articleMeta",
        flexFlow: "column nowrap",
        justifyContent: "center",
        alignItems: "center",
        mx: "auto",
        mb: "2rem",
        textAlign: "center",
        borderBottom: "2px solid",
        borderBottomColor: "decor",
        pb: "calc(1rem - 2px)"
      }}
    >
      {children}
    </Flex>
  )
}

export default LetterHeader
