import { Link } from "gatsby"

import * as sass from "./home-link.module.scss"

const HomeLink = ({ siteTitle }) => {
  return (
    <h1
      className={sass.siteTitle}
      sx={{ display: "block", textAlign: "center", maxHeight: "3rem" }}
    >
      <Link
        sx={{ variant: "links.title", display: "block", maxHeight: "3rem" }}
        to="/"
      >
        {siteTitle}
      </Link>
    </h1>
  )
}

export default HomeLink
