import { Flex } from "theme-ui"
import Main from "../components/Main"
import Masthead from "../components/Masthead"
import Colophon from "../components/Colophon"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useSerialMetadata } from "../hooks/use-serial-metadata"
import { useSiteNavigation } from "../hooks/use-site-navigation"

const Layout = ({ children, ...props }) => {
  const { siteTitle, siteUrl } = useSiteMetadata()
  const { headerMenu } = useSiteNavigation()
  const { serial } = useSerialMetadata()

  return (
    <Flex
      id="site"
      sx={{ minHeight: "100vh", width: "100%", flexDirection: "column" }}
    >
      <Masthead siteTitle={siteTitle} menu={headerMenu} />
      <Main>{children}</Main>
      <Colophon siteUrl={siteUrl} serial={serial} />
    </Flex>
  )
}

export default Layout
