import { Link } from "gatsby"

import * as sass from "./tag-link.module.scss"

const TagLink = ({ label, link, isCloud }) => {
  return (
    <div
      className={sass.tagLink}
      sx={{ borderRadius: "link", maxHeight: "2rem" }}
    >
      <Link
        to={link}
        sx={{
          variant: "links.tag"
        }}
      >
        #{label}
      </Link>
    </div>
  )
}

export default TagLink
