const swatch = {
  peacock: "#10b0e4",
  abyss: "#0F0F1A",
  midnight: "#252641",
  indigo: "#274060",
  dove: "#b0c0d3",
  columbia: "#d6edff",
  istanbul: "#7cc6fe",
  dodger: "#4ab3ff",
  naples: "#fada5e",
  gold: "#DAAF07",
  tangerine: "#FA9C5F"
}

const palette = {
  colors: {
    canvas: swatch.abyss,
    text: swatch.dove,
    background: swatch.midnight,
    primary: swatch.naples,
    secondary: swatch.istanbul,
    accent: swatch.columbia,
    highlight: swatch.indigo,
    muted: swatch.indigo,
    peacock: swatch.peacock,
    decor: swatch.tangerine,
    hover: {
      link: swatch.dodger,
      nav: swatch.gold
    }
  }
}

export default palette
