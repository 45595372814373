const measure = {
  breakpoints: ["640px", "800px", "1024px", "1440px"],
  // space: ["0.3rem", "0.5rem", "1rem", "2rem", "3rem", "5rem", "6rem"],
  sizes: {
    max: {
      xs: "100%",
      sm: "94%",
      md: "94%",
      lg: "1100px",
      xl: "1300px"
    },
    reg: {
      xs: "490px",
      sm: "550px",
      md: "600px",
      lg: "680px",
      xl: "750px"
    },
    container: "100%"
  },
  layout: {
    container: {
      width: "100%",
      maxWidth: "container"
    },
    regular: {
      width: ["max.sm"],
      maxWidth: ["reg.xs", "reg.sm", "reg.md", "reg.lg", "reg.xl"]
    },
    wide: {
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"],
      background: "background"
    },
    articleMeta: {
      width: ["100%"],
      maxWidth: ["94%", "600px", "680px", "750px", "900px"]
    },
    sticky: {
      maxWidth: "100%",
      zIndex: "sticky",
      position: "sticky",
      bottom: 0,
      marginTop: ["2rem", null, null, null, "1rem"],
      marginBottom: "2rem"
    }
    /*menu: {
      gridArea: "nav",
      justifySelf: "end",
      justifyContent: "flex-end",
      alignItems: "baseline",
      marginRight: "1rem"
    },
    navLink: {
      fontFamily: "navigation",
      fontWeight: "navigation",
      textTransform: "lowercase",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'smcp' 1",
      "&:after": {
        color: "decor",
        WebkitFontSmoothing: "auto",
        MozOsxFontSmoothing: "auto"
      }
    }*/
  },
  grids: {
    nav: {
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"],
      mx: "auto",
      my: "0"
    },
    testLog: {
      width: ["max.xs"],
      maxWidth: ["reg.xs", "reg.sm", "reg.md", "reg.lg", "reg.xl"],
      grid: "auto / 2fr 5fr",
      gridRowGap: "0",
      justifyContent: "center"
    },
    pagination: {
      width: "100%",
      gridTemplateRows: "4rem",
      alignContent: "center",
      backgroundColor: "background", //"rgba(16, 176, 228, 0.3)",
      padding: "0 0 1rem",
      position: "relative",
      zIndex: "sticky",
      transition: "changeMode",
      "&:before": {
        variant: "decor.pagination"
      },
      "&:after": {
        variant: "decor.pagination",
        paddingBottom: "4rem",
        zIndex: "-1 !important"
      }
    },
    pageLinks: {
      gridTemplateRows: "auto",
      gridColumnGap: "1rem",
      gridRowGap: "0",
      alignSelf: "center",
      alignContent: "center"
    },
    pageLinksNext: {
      variant: "grids.pageLinks",
      justifySelf: "end",
      paddingRight: "1rem"
    },
    pageLinksPrev: {
      variant: "grids.pageLinks",
      justifySelf: "start",
      paddingLeft: "1rem"
    }
  }
}

export default measure
