import { useStaticQuery, graphql } from "gatsby"

export const useSerialMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query SerialMetadataHook {
      site {
        siteMetadata {
          serial {
            copyright {
              title
              year
              license {
                name
                link
              }
            }
          }
        }
      }
    }
  `)

  return site.siteMetadata
}
