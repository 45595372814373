import { useStaticQuery, graphql } from "gatsby"

export const useSiteNavigation = () => {
  const { site } = useStaticQuery(graphql`
    query SiteNavigationHook {
      site {
        siteMetadata {
          siteNav {
            headerMenu {
              label
              link
            }
          }
        }
      }
    }
  `)

  return {
    headerMenu: site.siteMetadata.siteNav.headerMenu
  }
}
