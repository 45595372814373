const base = {
  radii: {
    none: "0",
    link: "0.3rem"
  },
  shadows: {
    focus: "0 0 0 2px rgba(16, 176, 228, 0.8)"
  },
  zIndices: {
    sticky: 100,
    menu: 998,
    toggle: 999,
    line: 999
  },
  fonts: {
    body: "nocturne-serif-lights",
    bold: "nocturne-serif",
    heading: "nocturne-serif",
    nav: "nocturne-serif",
    monospace: "VictorMono"
  },
  fontWeights: {
    journal: 500,
    body: 400,
    heading: 600,
    nav: 900,
    monospace: 400
  },
  decor: {
    line: {
      zIndex: "line",
      content: "' '",
      position: "absolute",
      overflow: "visible",
      borderBottom: "2px solid",
      borderBottomColor: "decor"
    },
    navigation: {
      variant: "decor.line",
      width: ["max.xs", "max.sm"],
      maxWidth: ["max.xs", "max.sm", "max.md", "max.lg", "max.xl"]
    },
    letter: {
      variant: "decor.line",
      width: ["max.sm"],
      maxWidth: ["reg.xs", "reg.sm", "reg.md", "reg.lg", "reg.xl"]
    },
    pagination: {
      variant: "decor.line",
      width: "100%"
    }
  }
}

export default base
