import * as sass from "./start-block.module.scss"

const LetterLocation = ({ children }) => {
  return (
    <p className={sass.location} sx={{ variant: "text.letter" }}>
      {children}
    </p>
  )
}

export default LetterLocation
