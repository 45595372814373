import { Link } from "gatsby"
import { alpha } from "@theme-ui/color"

import * as sass from "./nav-link.module.scss"

const NavLink = ({ label, link }) => {
  return (
    <div
      sx={{
        // variant: "layout.navLink"
        // background: () => alpha("peacock", 0.3),
        display: "inline-block",
        px: "1rem"
        //mx: "2px"
      }}
    >
      <Link to={link} partiallyActive={false} sx={{ variant: "links.nav" }}>
        {label}
      </Link>
    </div>
  )
}

export default NavLink
