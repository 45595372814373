import { Themed } from "@theme-ui/mdx"

import * as sass from "./copyright.module.scss"

const Copyright = ({ siteUrl, copyright }) => {
  return (
    <section
      sx={{
        variant: "text.monospace",
        px: "2rem",
        textAlign: "center"
      }}
    >
      <h2 sx={{ variant: "text.hidden" }}>Copyright</h2>
      <p className={sass.copyrightGraf}>
        <Themed.a property="dct:title" rel="cc:attributionURL" href={siteUrl}>
          {copyright.title}
        </Themed.a>
        {"\u00A0"}©{"\u00A0"}
        {copyright.year} is licensed under{" "}
        <Themed.a
          href={copyright.license.link}
          target="_blank"
          rel="license noopener noreferrer"
          sx={{
            whiteSpace: "nowrap"
          }}
        >
          {copyright.license.name}
        </Themed.a>
        . Author may be contacted{" "}
        <Themed.a href="https://www.tumblr.com/freestoneletters" target="_blank">
          here
        </Themed.a>
        .
      </p>
      <p className={sass.copyrightGraf}>
        Site design & code by{" "}
        <Themed.a href="https://thebitterdrop.com" target="_blank">
          Isidore Bloom
        </Themed.a>
        .
      </p>
    </section>
  )
}

export default Copyright
