import * as sass from "./article-title.module.scss"

const ArticleTitle = ({ title }) => {
  return (
    <div
      sx={{
        variant: "layout.articleMeta",
        borderBottom: "2px solid",
        borderBottomColor: "decor",
        pb: "calc(1rem - 2px)",
        mx: "auto",
        mb: "2rem"
      }}
    >
      <h1
        className={sass.articleTitle}
        sx={{
          variant: "text.inWhich",
          fontFeatureSettings:
            "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1, 'smcp' 1",
          //mt: "4rem",
          //pb: "2rem",
          textAlign: "center",
          fontStyle: "normal"
        }}
      >
        {title}
      </h1>
    </div>
  )
}

export default ArticleTitle
