// import BirchIcon from "../../images/svg/birch.inline.svg"
// import VictorIcon from "../../images/svg/victor.inline.svg"
// import GeraldineIcon from "../../images/svg/geraldine.inline.svg"
// import FatherIcon from "../../images/svg/father.inline.svg"
// import LamentIcon from "../../images/svg/lament.inline.svg"
import FrogIcon from "../../images/svg/frog.inline.svg"
// import DefaultIcon from "../../images/svg/placeholder.inline.svg"

const LetterIcon = () => {
  return (
    <div
      sx={{
        width: "3rem",
        height: "3rem"
        //borderRadius: "100%",
        //background: "primary",
        //mb: "1rem"
      }}
    >
      <FrogIcon sx={{ variant: "icons.letter" }} />
    </div>
  )
}

export default LetterIcon
