import * as sass from "./salutation.module.scss"

const Salutation = ({ greeting, recipient }) => {
  return (
    <p className={sass.salutation} sx={{ variant: "text.letter" }}>
      {greeting} {recipient},
    </p>
  )
}

export default Salutation
