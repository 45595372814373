import { Container } from "theme-ui"
import { Copyright } from "./text"

const Colophon = ({ siteUrl, serial: { copyright } }) => {
  return (
    <Container
      as="footer"
      variant="layout.wide"
      sx={{ py: "1rem", px: "2rem" }}
    >
      <Copyright siteUrl={siteUrl} copyright={copyright} />
    </Container>
  )
}

export default Colophon
