import * as sass from "./letter-heading.module.scss"

const NoteHeading = ({ title }) => {
  return (
    <>
      <h1
        className={sass.letterHeading}
        sx={{
          variant: "text.title",
          flex: "1 1  auto",
          px: ["2rem", "0"]
        }}
      >
        {title}
      </h1>
    </>
  )
}

export default NoteHeading
