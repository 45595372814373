import { Container } from "theme-ui"
import { alpha } from "@theme-ui/color"

const Main = ({ children }) => {
  return (
    <Container as="main" sx={{ flex: "1 1 auto" }}>
      {children}
    </Container>
  )
}

export default Main
