import * as sass from "./start-block.module.scss"

const LetterDate = ({ date }) => {
  return (
    <p className={sass.date} sx={{ variant: "text.letter" }}>
      {date}
    </p>
  )
}

export default LetterDate
