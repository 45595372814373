import * as sass from "./lists.module.scss"

const TagUl = ({ children }) => {
  return (
    <ul className={sass.bookList} sx={{ textAlign: "center" }}>
      {children}
    </ul>
  )
}

export default TagUl
