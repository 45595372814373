import {
  // Bdg,
  // BlockQuote,
  // EndChapter,
  // DropCap,
  // Fn,
  // FirstLine,
  ActSummary,
  Graf
  // QuoteGraf
} from "../components/text"

import {
  LetterLocation,
  LetterDate,
  Signature,
  Salutation,
  StartBlock
} from "../components/letters"

import { TestLog, TestLogEntry, JBold } from "../components/journals"

import { Ul } from "../components/lists"

import { Beta } from "../components/headings"

const components = {
  //DropCap,
  //FirstLine,
  //EndChapter,
  //QuoteGraf,
  ActSummary,
  TestLog,
  TestLogEntry,
  JBold,
  LetterDate,
  StartBlock,
  LetterLocation,
  Signature,
  Salutation,
  p: Graf,
  // h1: Alpha,
  h2: Beta,
  ul: Ul
  //BlockQuote,
  //Fn
}

export default components
