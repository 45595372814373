const JBold = ({ children }) => {
  return (
    <span
      sx={{
        fontFamily: "monospace",
        fontWeight: 600,
        textDecoration: "underline"
      }}
    >
      {children}
    </span>
  )
}

export default JBold
