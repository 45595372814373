import * as sass from "./act-summary.module.scss"

const ActSummary = ({ children }) => {
  return (
    <p
      className={sass.actSummary}
      sx={{
        variant: "text.title",
        //fontStyle: "italic",
        textAlign: "center",
        //width: "95%",
        mx: "auto"
      }}
    >
      {children}
    </p>
  )
}

export default ActSummary
