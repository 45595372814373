import * as sass from "./signature.module.scss"

const Signature = ({ signOff, author, marginLeft }) => {
  return (
    <div
      sx={{
        variant: "text.letter",
        textAlign: "left",
        pt: "1rem",
        ml: `${marginLeft}`
      }}
    >
      {signOff != null && (
        <p className={sass.signOff} sx={{ fontStyle: "normal" }}>
          {signOff},
        </p>
      )}
      <p className={sass.author}>{author}</p>
    </div>
  )
}

Signature.defaultProps = {
  signOff: null,
  marginLeft: "60%"
}

export default Signature
