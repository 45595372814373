import { Grid } from "theme-ui"

import * as sass from "./test-log.module.scss"

export const TestLog = ({ title, children }) => {
  return (
    <Grid
      as="section"
      variant="testLog"
      sx={{
        fontFamily: "monospace",
        fontWeight: "journal",
        fontStyle: "italic",
        pb: "calc(2rem - 2px)",
        borderBottom: "2px dashed",
        borderBottomColor: "secondary",
        mb: "2rem"
      }}
    >
      <h2
        className={sass.testLogTitle}
        sx={{
          gridColumn: "1 / 3",
          fontWeight: 600,
          textAlign: "right",
          color: "primary",
          pr: "1rem"
        }}
      >
        {title}
      </h2>
      {children}
    </Grid>
  )
}

export const TestLogEntry = ({ subject, children }) => {
  return (
    <>
      <p
        className={sass.testLog}
        sx={{ fontWeight: 600, justifySelf: "right", textAlign: "right" }}
      >
        {subject}:
      </p>
      <p className={sass.testLog} sx={{ fontWeight: "monospace" }}>
        {children}
      </p>
    </>
  )
}
