import base from "./base"
import palette from "./palette"
import typography from "./typography"
import measure from "./measure"

const theme = {
  config: {
    initialColorModeName: "dark"
  },
  ...palette,
  ...base,
  ...measure,
  ...typography
}

export default theme
