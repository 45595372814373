import * as sass from "./beta.module.scss"

const Beta = ({ children }) => {
  return (
    <h2 className={sass.beta} sx={{ variant: "text.betaTitle", color: "text" }}>
      {children}
    </h2>
  )
}

export default Beta
