const typography = {
  text: {
    body: {
      fontFamily: "body",
      fontWeight: "body",
      color: "text"
    },
    graf: {
      variant: "text.body",
      hyphens: "auto",
      fontFeatureSettings:
        "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1",
      //WebkitFontSmoothing: "auto",
      //MozOsxFontSmoothing: "auto",
      "&:first-of-type": {
        textIndent: "0"
      }
    },
    letter: {
      variant: "text.graf",
      color: "accent",
      fontStyle: "italic"
    },
    inWhich: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "primary",
      fontStyle: "italic",
      fontFeatureSettings:
        "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1"
    },
    title: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "secondary",
      fontFeatureSettings:
        "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1, 'smcp' 1"
    },
    betaTitle: {
      fontFamily: "heading",
      fontWeight: "heading",
      color: "text",
      textAlign: "center",
      fontFeatureSettings:
        "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1, 'c2sc' 1"
    },
    monospace: {
      fontFamily: "monospace",
      fontWeight: "monospace",
      hyphens: "none",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'case' 1",
      color: "text"
    },
    hidden: {
      clip: "rect(1px, 1px, 1px, 1px)",
      clipPath: "inset(50%)",
      height: "1px",
      width: "1px",
      margin: " -1px",
      overflow: "hidden",
      padding: "0",
      position: "absolute"
    }
  },
  links: {
    base: {
      textDecoration: "none",
      borderRadius: "link",
      cursor: "pointer",
      "&:focus-visible": {
        outline: "none",
        boxShadow: "focus"
      }
    },
    body: {
      variant: "links.base",
      color: "secondary",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.link",
        textDecoration: "underline"
      }
    },
    title: {
      variant: "links.base",
      fontFamily: "heading",
      fontWeight: "heading",
      fontStyle: "italic",
      color: "secondary",
      maxHeight: "2rem",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.link",
        textDecoration: "underline"
      }
    },
    nav: {
      variant: "links.body",
      fontFeatureSettings:
        "'kern' 1, 'liga' 1, 'clig' 1, 'dlig' 1, 'onum' 1, 'pnum' 1, 'case' 1, 'smcp' 1",
      fontFamily: "nav",
      fontWeight: "nav",
      textTransform: "lowercase",
      color: "primary",
      "&:hover, &:active, &:focus-visible": {
        color: "hover.nav",
        textDecoration: "underline"
      }
    },
    pagination: {
      fontFamily: "heading",
      fontWeight: "nav",
      textTransform: "uppercase",
      fontFeatureSettings: "'kern' 1, 'liga' 1, 'dlig' 1, 'case' 1, 'c2sc' 1",
      variant: "links.base",
      color: "primary",
      textAlign: "center",
      ".primary": { fill: "hover.nav" },
      ".secondary": { fill: "hover.nav" },
      "&:hover, &:active, &:focus-visible": {
        color: "hover.nav",
        textDecoration: "underline",
        ".primary, .secondary": {
          fill: "primary"
        }
      }
    },
    paginationGrid: {
      variant: "links.pagination",
      display: "grid",
      gridTemplateColumns: "repeat(2, auto)",
      gridTemplateRows: "2rem",
      placeContent: "center"
    },
    tag: {
      variant: "links.base",
      fontFamily: "heading",
      fontWeight: "heading",
      fontStyle: "italic",
      color: "secondary",
      background: "canvas",
      border: (theme) => `1px solid ${theme.colors.hover.link}`,
      padding: "2px 1.25rem",
      "&:hover, &:active, &:focus": {
        textDecoration: "underline",
        background: "canvas",
        borderColor: "decor",
        color: "primary"
      }
    }
  },
  icons: {
    arrow: {
      width: "2rem",
      height: "2rem"
    },
    letter: {
      padding: "0.25rem",
      stroke: "decor"
    }
  },
  styles: {
    root: {
      textRendering: "optimizeLegibility",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      background: "canvas"
      // transition: "changeMode"
    },
    body: {
      height: "100vh"
    },
    p: {
      fontFamily: "body"
    },
    a: {
      variant: "links.body"
    },
    em: {
      fontStyle: "italic",
      lineHeight: "100%"
    },
    strong: {
      lineHeight: "100%",
      fontFamily: "bold",
      fontWeight: "600"
    }
  }
}

export default typography
