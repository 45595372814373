import { Link } from "gatsby"

const TagLi = ({ id, slug, in_which }) => {
  return (
    <li
      key={id}
      sx={{
        fontFamily: "body",
        fontWeight: "body",
        paddingBottom: "1rem",
        fontFeatureSettings:
          "'kern' 1, 'liga' 1, 'dlig' 1,'case' 1, 'onum' 1, 'tnum' 1, 'smcp' 0",
        "&:before": { content: "'\u2022\u00A0'", variant: "text.listMarker" }
      }}
    >
      <Link to={slug} sx={{ variant: "links.body", fontStyle: "italic" }}>
        In which {in_which}
      </Link>
    </li>
  )
}

export default TagLi
