import { Flex } from "theme-ui"

import * as sass from "./header-nav.module.scss"

const HeaderNav = ({ children }) => {
  return (
    <Flex
      as="nav"
      sx={{
        //variant: "layout.regular",
        flexFlow: "row nowrap",
        justifyContent: "center"
        //height: "3rem"
        //alignSelf: "flex-end"
      }}
      className={sass.headerNav}
    >
      {children}
    </Flex>
  )
}

export default HeaderNav
